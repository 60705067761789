
section{
  
    width: 100%;
    height: 100%;
    margin-top: 150px;
    

    @media only screen and (max-width: 2000px) {
   
        width: 400px;
        height: 250px;
        
    }
}
.container{
    position: relative;
    left:43%;
    top:50%;
    transform: translate(-50%,-50%);
    width:1100px;
    height:500px;
    padding-right: 20px;
    box-shadow: 0 7px 15px rgba(0,0,0, .3);

    @media only screen and (max-width: 600px) {
       left: 50%;
        width: 400px;
        height: 190px;
      
    }

}
#slide{
    width:max-content;
}

    .item{
        width:150px;
        height:100px;
        background-position: 50% 50%;
        display: inline-block;
        transition: 0.5s;
        background-size: cover;
        position: absolute;
        z-index: 1;
        left: 0;
        top:50%;
        transform: translate(0,-50%);
        border-radius: 5px;
        box-shadow:  0 30px 50px #505050;
         
        @media screen and (max-width: 600px) {
            width:190px;
            height:95px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover; 
           }
       
    }

.item:nth-child(1),
.item:nth-child(2){
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
   
    width:100%;
    height:100%;
    box-shadow: none;

}
.item:nth-child(3){
    top: 80%;
    left:50%;
    @media screen and (max-width: 600px) {
        box-shadow: 0 4px 8px rgba(0,0,0, .2);
        margin-top: 100px;
        left:0%;
       }
}
.item:nth-child(4){
    top: 80%;
    left:calc(50% + 165px);
    @media screen and (max-width: 600px) {
        box-shadow: 0 4px 8px rgba(0,0,0, .2);
        margin-top: 100px;
        left:52%;
       }
}
.item:nth-child(5){
    top: 80%;
    left:calc(50% + 331px);
    @media screen and (max-width: 600px) {
        box-shadow: 0 4px 8px rgba(0,0,0, .2);
        margin-top: 100px;
        left:52%;
       }
}
.item:nth-child(n+6){
    top: 80%;
    left:calc(50% + 600px);
    opacity: 0;
    @media screen and (max-width: 600px) {
        box-shadow: 0 5px 8px rgba(0,0,0, .2);
        margin-top: 100px;
        left:52%;
       }
}
.item .content{
    position: absolute;
    top:72%;
    left:300px;
    width:300px;
    text-align: left;
    padding:0;
    transform: translate(0,-50%);
    display: none;
    font-family: system-ui;

    @media screen and (max-width: 600px) {
        top:55%;
        left:20px;
       }
}
.item:nth-child(2) .content{
    display: block;
    z-index: 11111;

}
.item .name{
    font-size:25px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 80px;
    opacity: 0;
    color: #fff;
    text-align: center;
    padding-bottom: 8px;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0, .2);
    width: 200px;
    background-color: rgba(0,0,0, .1);
    text-shadow:  0 4px 8px rgba(0,0,0, .6);
    animation:showcontent 1s ease-in-out 1 forwards;

    @media screen and (max-width: 600px) {
        font-size: 15px;
        padding-bottom: 3px;
        width: 100px;
        font-weight: 900;
        margin-bottom: 0px;
       }
}

.item button{
    font-size: 15px;
    font-weight: 700;
    padding: 3px 10px;
    border:none;
    border-radius: 5px;
    opacity: 0;
    box-shadow:  0 8px 12px rgba(0,0,0, .3);
    transition: .2s;
    animation:showcontent 1s ease-in-out 0.3s 1 forwards;

    @media screen and (max-width: 600px) {
        border-radius: 2px;
        padding:5px;
        font-size: 8px;
        font-weight: 500;
       }
}
.item button:hover{
    border-bottom: 1px solid #000;
    box-shadow:  none;
}
@keyframes showcontent{
    from{
        opacity: 0;
        transform: translate(0,100px);
        filter:blur(33px);
    }to{
        opacity: 1;
        transform: translate(0,0);
        filter:blur(0);
    }
}
.buttons{
     position: absolute;
     display: flex;
     justify-content: space-evenly;
     width: 150px;
     bottom:30px;
     z-index: 222222;
    

     @media screen and (max-width: 600px) {
        bottom:5px;
        right: 10px;
        width: 60px;
       }
  
}
.buttons i{
    color: #fff;
    text-shadow: 0 1px 3px rgba(0,0,0, 1);
    font-size: 30px;
    border-radius: 5px;
    font-weight: 900;
    transition: 0.3s;

    @media screen and (max-width: 600px) {
        font-size: 25px;
        font-weight: 900;
       }
  
}.buttons i:hover{
    padding-left: 5px;
}


// code

.code_container{
    width: 100%;
    height: 70vh;
    display: flex; 
    padding: 0px;
    // overflow: hidden;

    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
       
     }
}

.code{
    width: 400px;
    height: 400px;
    margin: 20px;
    margin-top: 20px;
    overflow: scroll;
    scroll-behavior: smooth;
    box-shadow: 0 2px 8spx rgba(0,0,0, .2);
    border-radius: 5px;

    
}



.code h1{
    position: absolute;
    width: 400px;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid #000;
    // border-radius: 5px;
    text-shadow: 0 2px 6px rgba(0,0,0, .3);


}

.mycode{
	color: rgb(95, 95, 225);
    padding-top: 20px;
   
}
