
.skills {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 3rem;
 }

 .head-text{
    margin-top: -12rem;
 }
  
.app__skills-item {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem;
    transition: .3s;
    padding: 10px;
    
    img { 
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      padding: 15px;
      width: 100%;
      height: 100px;
      object-fit: cover;
      transition: .3s;

      &:hover{
     
        box-shadow: none;
      }
    }
        
    p{
      font-size: 16px;
      font-weight: 700;
      padding: 10px;
        text-align: center;
    }

    .rest{
      width: 100px;
      height: 100px;
    }
  
    @media screen and (min-width: 2000px) {
      width: 370px;
      margin: 2rem 4rem;
  
      img {
        height: 320px;
      }
    }
}
